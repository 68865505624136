@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';


.msgBanner{@include sec-default();padding:15px 0;background-color:$notice;text-align:center;
  &.show940{display:none;}
  .medDef{width:95%;}
  &, p, h4{color:$white;}
  p{margin-bottom:0;line-height:1.5em;}
  p+p{margin-top:5px;}
  h4{font-size:20px;font-weight:bold;letter-spacing:2px;margin-bottom:2px;}
  b{letter-spacing:.5px;}
  .und{text-decoration:underline;}
  br{display:none;}
  @include bp(tw){
    br.one{display:block;}
  }
  @media (max-width:940px){
    &.hide940{display:none;}
    &.show940{display:block;}
    .medDef{max-width:600px;}
    p{font-size:16px;}
  }
  @include bp(ph){
    .medDef{max-width:420px;}
    h4{font-size:18px;}
    p{font-size:15px;}
    br.one, br.two{display:none;}
    p+p{margin-top:10px;}
  }
}

.cbr{display: none;}
.phText{color:$red;margin-left:auto !important;margin-right:auto !important;max-width:700px;
  &.white{color:$white;}
}

@include bp(tn){
  .phText{max-width:550px;}
}

@include bp(md){.callLink.footer .cbr{display: block;}}
@include bp(og){.callLink.footer .cbr{display: none;}}