// SEARCH KPKP HERE WHEN OTHER FORMS ARE LIVE. 
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.FlyerLay{@include sec-default();padding:40px 0px 60px;background-color:$blue;text-align:center;
  .OuterTitle{@include sec-default();
    h1,h2,h3,h4,p{color:$white;text-transform:uppercase;}
    h4{font-weight:900;letter-spacing:2px;margin:0;padding:10px;font-size:19px;
      background-color:#f5f5f5;color:$teal;
    }
    p{color:$logold;font-weight:bold;letter-spacing:2px;font-size:22px;line-height:1.4em;}

    h1{font-weight:700;letter-spacing:3px;line-height:1.3em;font-size:26px;margin-bottom:0;
      span{position:relative;z-index:0;font-size:37px;line-height:1.5em;background-color:$blue;padding:0 10px;letter-spacing:5px;}
      span::before,span::after{@include after();height:2px;bottom:43%;background-color:$white;}
      span::before{left:-40%;}
      span::after{right:-40%;}
    }
  }
  .whitebox{@include sec-default();background-color:$white;padding:20px 0;box-shadow:0px 10px 20px rgba($black,.2);}
  .InnerTitle{@include sec-default();
    h1{color:$teal;margin-bottom:5px;font-weight:900;}
    h2{margin-bottom:15px;}
  }
  iframe{@include sec-default();height:250px;}
  table,tr{@include sec-default();margin:0 auto;}

  .FlyerContent1,.FlyerContent2{@include sec-default();
    h4{font-weight:900;margin-bottom:0;}
    li, li a{font-size:20px;font-weight:bold;color:$blue;}
    li{margin-bottom:10px;}
  }
  .FlyerContent1{padding-top:20px;
    h4{font-size:1.2rem;color:$blue;text-decoration:underline;}
    p{line-height:1.4em;font-size:17.5px;}
    p span{color:darken($teal,8%);font-style:italic;}
  }
  .FlyerContent2{
    // tbody,tr,td{@include sec-default();}
    table,tbody,tr{@include sec-default();}
    tr{margin-bottom:50px;}
    tr:last-child{margin-bottom:30px;}
    tr hr{margin-top:7px;}

    h4,p,a{line-height:1.4em;}
    h4{color:$logoBlue;font-size:18px;text-transform:uppercase;letter-spacing:2px;}
    p span, p span a{color:darken($teal,8%);font-weight:bold;}
  }

  .FlyerTable{@include sec-default();
    tbody,tr{@include sec-default();}
    td{vertical-align:top;width:33%;}
    // td:first-child{padding-right:12px;}
    // td:last-child{padding-left:10px;}
    // td:nth-child(2){border-right:$borderB;border-left:$borderB;padding:0 15px;}
    
    h4{color:$logoBlue;font-size:18px;text-transform:uppercase;letter-spacing:2px;line-height:1.4em;font-weight:900;margin-bottom:0;}
    p{font-size:20px;position:relative;z-index:0;padding-bottom:7px;
      &::after{@include after();left:50%;margin-left:-32.5px;width:65px;height:1px;border-bottom:1px solid rgba($black,.2);}
    }
    
    li{color:$logoBlue;margin-bottom:15px;line-height:1.4em;font-size:16px;}
    li b{color:$teal;font-weight:800;}
    li:last-child{font-weight:800;color:$blue;padding-top:10px;border-top:1px solid rgba($blue,.25);text-transform:uppercase;width:70%;@include center-block();}
  }
  .imageRow img{width:100%;}

  .DropContent{text-align:center;
    button:hover{@include ease(1s);}
    button{width:100%;font-weight:bold;color:$teal;text-align:center !important;padding:0;font-size:19px;}
    // button::before{float:none;display:inline-block;}
    li div{margin-top:0;}
    li div p{width:90%;@include center-block();}
    li div p:first-child{margin-top:10px;}
  }

  &.external {
    .flContent{padding:0;}
    .whitebox{padding-bottom:0;}
  }

  .lessTop{padding-top:10px;}
  .less{padding-top:0;}

  &.tableTrue {
    .medDef{max-width:1200px;}
    .whitebox .inside{width:97%;}
    @media (min-width:768px){.whitebox{padding-bottom:20px;}}
  }

  &.RegistrationPage p, &.PokerRunPage p b{font-weight:900;}
  
  &.RegistrationPage .medDef{width:95%;}
  
  &.PokerRunPage h5{width:75%;@include center-block();font-size:19px;font-weight:bold;color:$red;margin-bottom:30px;}

  &.TrailerPage{
    .OuterTitle{
      h1{line-height:1em;}
      h1,h1 span{font-size:30px;}
    }
    .FlyerTable{
      tr{margin-bottom:10px;}
      h4,p,a{line-height:1.4em;}
      p{font-size:17px;@include sec-default();}
      a{font-weight:normal;}
      p a{color:darken($teal,8%);}
      p::after{display:none;}
      hr{margin:7px auto 20px;}
    }
  }

  &.ContactPage{
    // .whitebox{position:relative;z-index:0;}
    // .whitebox::after{@include after();left:0;height:38.5%;background-color:#f7f7f7;}
    .flContent,.whitebox{position:relative;z-index:0;
      &::after{@include after();left:0;height:55%;background-color:#f5f5f5;}
    }
    .whitebox::after{height:20px;}
    td{@include sec-default();}
    tr:last-child{margin-bottom:0px;}
    tr:first-child{margin:15px 0 30px;
      td{display:inline-block;text-align:left;
        &:first-child{float:left;width:52%;padding-left:20px;}
        &:last-child{float:right;width:40%;text-align:center;}
      }
    }
    p {
      a{color:inherit;}
      b{font-weight:800;}
      span{font-weight:900;text-transform:uppercase;font-size:15px;letter-spacing:3px;color:$teal;line-height:1em;margin-bottom:0;}
    }
    ul{margin-bottom:20px;}
    input[type=image]{width:165px;@include center-block();}
  }

  &.RegisterPage{
    .whitebox,.flContent{padding-top:0;}
    .whitebox{background-color:transparent;box-shadow:none;}
    h4{background-color:$ecBlue;color:$white;
      span{text-decoration: underline;}
    }
  }

  &.PartyTixPage, &.DrawDownPage{
    .whitebox{background-color:#f5f5f5;}
  }
  
  // KPKP TEMP PADDING UNTIL FORMS ARE LIVE AGAIN!
  // &.PartyTixPage .whitebox{padding-bottom:0;}

  &.DrawDownPage {
    .flContent{padding-top:10px;}
    h3{color:$teal;font-size:32px;font-weight:900;margin-bottom:0px;text-transform: uppercase;}
    h4{color:$ecBlue;font-family:$emph;text-decoration:none;font-size:35px;margin:2px 0;letter-spacing:1px;}
    h5{font-size:22px;margin-bottom:5px;color:$teal;
      a{font-weight:600;color:inherit;}
    }
    p,li{position:relative;z-index:0;font-size:20px;color:$lightest;}
    ul p{font-family:$emph;font-size:32px;letter-spacing:2px;font-weight:500;margin-bottom:5px;}
    li{font-weight:normal;font-size:19px;display:inline-block;margin:6px 1%; padding:0 10px;
      &::after{@include after();width:5px;height:5px;background-color:$white;border-radius:50%;right:-8px;bottom:50%;margin-bottom:-2.5px;display:none;}
      &:nth-child(2n+2)::after{display:block;}
      span{font-family:$emph;font-size:25px;letter-spacing:2px;font-weight:500;}
    }
  }
  
  &.PartyTixPage{    
    .InnerTitle div{display:flex;justify-content:space-evenly;padding:0 30px;}
    p{line-height:1.3em;}
    p b{color:darken($teal,8%);font-weight:800px;}
  }

  &.FallbackPage h1{margin-bottom:10px;}
  &.GalleryPage {
    p{width:90%;@include center-block();}
    .flContent{padding-top:0;}
  }

  &.PlungePage{
    p b{font-weight:900;}
    em{color:$logold;font-weight:900;}
    .FlyerContent1{padding-top:10px;}
    .fc2{width:100%;}
  }


  //VERSION PDF
  &.StopsPage2{
    .whitebox{padding-bottom:0;}
    h4{background-color:darken($ecBlue,5%);color:$white;font-size:16px;padding:5px;margin:15px 0 5px;}
  }


  &.HotelsPage{
    .InnerTitle a{display:inline-block;margin:5px 10px;font-size:16px;line-height:1.2em;}

    td:first-child{width:58%;}
    td:last-child{width:35%;border-right:none;}
    
    td a:first-child{width:95%;max-width:300px;@include center-block();}
    img{max-width:300px;}
    h3{font-size:23px;margin:40px 0 5px;text-transform:uppercase;letter-spacing:1px;color:$teal;font-weight:bold;text-decoration:underline;}
    p::after{display:none;}
  }

  @media (min-width:1024px){
    .FlyerTable{
      td:not(:last-child){padding-right:15px;}
      td:not(:first-child){border-left:$borderB;padding-left:15px;}
    }

    &.TrailerPage{min-height:70vh;}

    &.HotelsPage{
      .InnerTitle{display:none;}
      td{padding:0 40px !important;}
    }
  }

  // KPKP do this for all form pages WHEN OTHER FORMS ARE LIVE ???
  @media (min-width:1004px){
    &.DrawDownPage{
      // p{position:relative;}
      iframe{margin-top:-30px !important;}
    }
  }

  @include bp(tw){
    .OuterTitle h1{
      span::before{left:-33%;}
      span::after{right:-33%;}
    }

    &.SponsorsPage .medDef{width:95%;}

    &.ContactPage .flContent::after{height:56%;}
  }

  @include bp(tb){
    .OuterTitle h1{font-size:24px;
      span{font-size:35px;}
    }

    &.ContactPage .flContent::after{height:58%;}
    &.GalleryPage p{width:55%;}
  }

  @include bp(br){
    .OuterTitle{
      h1{font-size:22px;letter-spacing:2px;
        span{font-size:30px;letter-spacing:3px;}
        span::before{left:-27%;}
        span::after{right:-27%;}
      }
      h4{font-size:18px;}
      p{font-size:19px;}
    }
    
    .InnerTitle {
      h1{font-size:1.5rem;}
      h2{font-size:1.2rem;}
    }
    .FlyerContent1{
      h4{font-size:1.1rem;}
      p{font-size:17px;}
      li, li a{font-size:18px;}
    }

    .FlyerTable{@include sec-default();
      td{@include sec-default();}
      // td:first-child, td:last-child{padding:0 15px;}
      // td:nth-child(2){border:none;background-color:rgba($lt-blue,.4);padding:20px 15px 10px;margin:5px 0 15px;}
      td{padding-left:10px;padding-right:10px;}
      td:not(:first-child){margin-top:15px;}
      td:nth-child(even){background-color:rgba($lt-blue,.4);padding-top:20px;padding-bottom:10px;}
      p{font-size:18px;}
    }  

    &.TrailerPage {
      .OuterTitle{
        h1,h1 span{font-size:25px;}
        h4{padding:10px 20%;margin-top:10px;}
      }
      
      .FlyerTable {
        td:not(:first-child){margin-top:30px;}
        td:nth-child(even){padding-bottom:20px;}
      }
    }
    
    &.GalleryPage p{width:90%;max-width:480px;}
    &.PokerRunPage h5{width:90%;font-size:18px;}

    &.HotelsPage{
      &.tableTrue .medDef{max-width:100%;}
      tr{max-width:500px;@include center-block();}
      td{width:100% !important;}
      td:first-child{margin-top:30px;}
      td:last-child{background-color:transparent;margin-top:20px;border-top:1px solid rgba($black,.1);}//width:35%;border-right:none;}
      
      td a:first-child, img{max-width:240px;}
      h3{font-size:19px;margin-top:30px;}
      td:last-child h3{margin-top:15px;}
    }

    &.DrawDownPage {
      .flContent{padding-top:0px;}
      h3{font-size:27px;}
      h4{font-size:28px;}
      h5{font-size:21px;}
      ul p{font-size:29px;}
      li{font-size:17px;margin:6px 1%; padding:0 5px;
        span{font-size:23px;letter-spacing:2px;}
        &::after{display:block;width:3px;height:3px;right:-7px;}
      }
    }
  }

  @include bp(nn){
    &.PartyTixPage{
      .InnerTitle div{width:95%;max-width:550px;@include center-block();padding:0;}
      p{font-size:16px;display:inline-block;width:50%;}
    }
  }

  @include bp(tn){
    &.ContactPage{
      .flContent::after{height:60%;}
      tr:first-child{margin-top:5px;}
      tr:first-child td:first-child{width:55%;}
    }
  }

  @include bp(md){
    &.TrailerPage .OuterTitle h4{padding:10px 13%;}
    &.StopsPage2 h4{font-size:15px;} //VERSION PDF
  }

  @include bp(og){padding:20px 0;
    .whitebox{padding:20px 0 30px;}
    .InnerTitle h1{font-size:1.4rem;}

    .OuterTitle{
      h1{font-size:19px;overflow:hidden;
        span{font-size:25px;}
      }
      p,h4{font-size:17px;}
    }
    
    .FlyerContent1 .inside{width:90%;}
    .FlyerContent2 h4{font-size:17px;}

    .DropContent{
      button{font-size:17px;}
      li div p{font-size:16px;width:95%;}
    }
  
    &.tableTrue{
      .whitebox{padding:15px 0;}
      .medDef{width:90%;max-width:500px;}
      .whitebox .inside{width:100%;}
    }

    &.TrailerPage .OuterTitle {
      h1,h1 span{font-size:22px;}
      p br{display:block;}
      h4{padding:10px 10%;}
    }

    &.ContactPage{
      // .whitebox::after{height:36%;}
      .InnerTitle p{font-size:16px;}
      .flContent::after{height:52%;}
      tr:first-child{margin:0 0 10px;}
      tr:first-child td:first-child,tr:first-child td:last-child{width:100%;text-align:center;}
    }

    &.PokerRunPage h5{width:99%;}

    &.DrawDownPage {
      h3{font-size:24px;}
      h4{font-size:24px;}
      h5{font-size:18px;}
      ul p{font-size:29px;}
      li{font-size:17px;margin:6px 1%; padding:0 5px;
        span{font-size:23px;letter-spacing:2px;}
        &::after{display:block;width:3px;height:3px;right:-7px;}
      }
    }
  }

  @include bp(oc){
    &.PartyTixPage{
      p{@include sec-default();}
      p:last-child{margin-bottom:0px;}
    }
    &.ContactPage .flContent::after{height:54%;}

    &.DrawDownPage{
      .OuterTitle h1{font-size:17px;
        span{font-size:21px;}
      }
      h3{font-size:18px;}
      h4{font-size:21px;letter-spacing:0;}
      h5, h5 a{font-size:18px;}
    }      

    &.HotelsPage{
      .InnerTitle a{margin:2px 5px;}
    }  
  }

  @include bp(fi){
    .InnerTitle {
      h1{font-size:1.2rem;}
      h2{font-size:1rem;}
      a{font-size:14px;padding:7px 20px;}
    }

    .OuterTitle{
      h1{font-size:17px;
        span{font-size:22px;}
      }
    }

    .FlyerContent2 h4{font-size:17px;}

    &.TrailerPage .OuterTitle {
      h4{padding:10px 5%;}
      h1, h1 span{font-size:20px;}
    }

    &.StopsPage2{
      h4{font-size:14px;line-height:1.2em;margin-bottom:0;
        span{width:95%;max-width:250px;@include center-block();} 
      }
      // h4+a{padding:0;}
    } //VERSION PDF

    &.ContactPage .flContent::after{height:56%;}

    &.DrawDownPage{
      h3,h4,h5{@include center-block();line-height:1.2em;margin-bottom:10px;}
      h3{max-width:230px;}
      h4{max-width:300px;}
      h5{max-width:250px;}

      li{font-size:16px;@include sec-default();margin:0 0 2px;padding:0;
        &::after,&:nth-child(2n+2)::after{display:none;}
      }
      li span{font-family:$body;font-size:inherit;letter-spacing:0;font-weight:500;}

    }
  }
}