$black:#000;
$white:#fff;
$red:#ed1e24;
$light:#ececec;
$f7:#f7f7f7;
$f5:#f5f5f5;
$dark:#1a1a1a;
$gray:#9d9d9d;
$font-color:#9d9d9d;
$gold:#b2ae9e;
$lt-blue:#d8e9f4;
$cream:#f7f5f2;
$green:#33CC66;
$navy:#27343e;
$red:#ff3720;
$red2:#e5311c;
$notice:#9a1405;
$blue:#506acb;
$logoBlue:#0d518c;
$logold:#f6c835;
$lt-teal:#aee3dd;
$lightest:#d6f1ee;
$teal:#36bbaa;
$aqua:#68b882;
$sea :#68b882;
$orange: #ffa807;
$ecBlue:#009ac8;
$ddBg:#05040a;
$pink: #fa67c0;
$bgBlue:#016aa4;
$blue24:#164AAD;

$borderB:1px solid rgba($black,.1);
$borderW:1px solid rgba($white,.1);
$borderR:1px solid rgba($red,.1);

$lato:'Lato', sans-serif;
$nano:'nano_icon_font3';
// $courg:'Courgette', cursive;
$bounce:'Birthstone Bounce', cursive;

$body:$lato;
$emph: $bounce;