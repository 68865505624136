// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Gallery{@include sec-default();text-align:center;
  .imageGallery{@include sec-default();}
  .galleryImg{display:inline-block;margin:2.5px 5px;
    img{height:300px;}
  }
  li img{@include sec-default();}
}