// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Plunge{@include sec-default();
  .links{@include sec-default();margin:15px 0;}
  a{padding:7px 25px 9px;margin:0 5px;}
  @include bp(ph){a{padding:7px 15px 9px;}}
  @include bp(fi){
    .links{@include center-block();max-width:230px;}
    .links a{@include sec-default();margin:5px 0;padding:7px 5px 9px;}
    .links a:last-child{margin-bottom:15px;}
  }
}
.ddPlax{padding:30px 0 25px;margin:15px 0 30px;background-color:$teal;position:relative;z-index:0;
  &::after{@include after();width:55%;right:0;background-color:darken($teal, 12%);}
  .il{display:inline-block;text-align:left;}
  .one{float:left;width:37%;}
  .two{float:right;width:50%}
  p{color:$white;text-shadow:0px 1px 1px rgba($black,.2);}
  p.starter{font-weight:900;margin-bottom:10px;}
  p.pleft1{margin-bottom:10px;position:relative;z-index:0;padding-left:10px;line-height:1.2em;
    &::before{@include before();width:4px;height:4px;background-color:$white;border-radius:50%;top:50%;margin-top:-2px;}
  }
  hr{width:100px;border-color:rgba($white,.3);margin-top:10px;}
  .smBtn{display:none;}

  @include bp(tn){padding:15px 0 0px;margin-top:0px;margin-bottom:20px;
    &::after{display:none;}
    .sectionWrap{max-width:470px;}
    .il{text-align:center;}
    .one, .two{width:100%;}
    .two{padding-top:20px;margin-top:10px;border-top:1px solid rgba($white,.3);}
    p.pleft1{padding-left:0;padding-bottom:15px;
      &::before{top:100%;left:50%;margin-left:-2px;}
    }
    hr{@include center-block();}
    .lgBtn{display:none;}
    .smBtn{display:block;max-width:140px;margin:15px auto 20px;}
  }
}

.levels{
  .sectionWrap{max-width:600px;}
  .group+.group{margin-top:30px;}
  p.levelName{font-size:19px;position:relative;z-index:0;padding-bottom:7px;margin:15px 0 10px;font-weight:900;
    &::after{@include after();width:100px;height:1px;left:50%;margin-left:-50px;border-bottom:1px solid rgba($black,.25);}
  }
  p:not(.levelName){font-size:17px;color:$logoBlue;
    b{color:$teal;}
  }
  .spacer1{height:5px;}

  @include bp(tn){
    .sectionWrap{width:95%;max-width:500px;}
    p.levelName{font-size:18px;margin-bottom:5px;}
    p:not(.levelName){font-size:16px;}
    .group+.group{margin-top:20px;}
  }
}