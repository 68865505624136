@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

// KPKP moving mobile menu to tw - bc of accommodations addition

.Header{@include sec-default();text-align:center;position:relative;z-index:2;
  &::before{@include before();height:40px;background-color:$teal;}
  .topBar{@include sec-default();padding-top:5px;position: relative;
    .socialLinks{float:right;width:50%;}
    span.line{width:1px;height:18px;border-left:1px solid $white;margin:0 10px 0 5px;}
    .modalLink{display:inline-block;color:$white;font-size:15px;}
  }

  .navMm{@include sec-default();background-color:transparent;position:static;padding:10px 0 2px;}
  .logoBlk, .navBarCustom, .navItem{display:inline-block;}
  .logoBlk{width:145px;float:left;margin-top:-40px;}
  .navBarCustom{margin-top:40px;float:right;text-align:right;}

  .navItem{margin:0 0 0 40px;
    &, a{color:$black;font-weight:bold;}
    a{text-transform:none;font-size:1rem;padding:0;margin:0;min-height:auto;font-family:$body;}
    .parentIcon svg{height:16px;margin-top:2px;}
    span.num{font-size:.92em;padding-left:5px;}
  
    &.withDrop:hover, &.withDrop:hover a, &.withDrop a:hover{opacity:1 !important;}
    &.homeLink{display:none;}
    &.attn a{color:$red;}
    // &.draw a{font-family:$emph;}
  }
  .navItem.mob,.dropLi.mob{display:none;}
  .custDrop{text-align:left;width:210px;padding:0;
    li{padding:10px 15px;}
    a{font-size:16px;}
    li:hover{background-color:$teal;
      a{color:$white;opacity:1;}
    }
    li.sig{background-color:$red;
      a{color:$white;}
    }
  }

  .mobNav{display:none;float:right;margin-top:30px;}
  .mobileNavBtn{@include sec-default();width:35px;background-color:$logoBlue;padding:5px 7px;box-shadow:0px 10px 30px rgba($black,.2);
    span{@include sec-default();height:2px;margin:2.5px 0;background-color:$white;}
    p,.drawerText{display:none;}
  }

  @media (max-width:1300px){.navItem{margin-left:30px;}}
  @include bp(tw){
    .navItem{margin-left:25px;
      a{font-size:16.5px;}
    }
    // *** IF drawdown/long extra nav link
    // .navMm .navItem.homelink{display:none;}
    // .navItem:nth-child(2){margin-left:0;}

  // }
  // @include bp(br){
    .topBar{text-align:right;padding-right:15px;
      .socialLinks{width:90%;}
    }

    .hdWrap{max-width:700px;}
    .navBarCustom{display:none;}
    .mobNav{display:inline-block;}
  }
  @include bp(fi){
    .topBar {
      span.line,.modalLink{display:none;}
    }
    .hdWrap{width:85%;}
    .logoBlk{width:120px;}
    .mobNav{margin-top:20px;}
  }

  @include bp(xx){.hdWrap{width:90%;}}
}

// .cNavDrawer{background-color:$blue;}
.SideNavList{padding:40px 30px 0;width:300px;
  .SideNav{margin-top:10%;}
  .closeIcon {color:$white;position:absolute;right:10px;top:15px;cursor:pointer;font-weight:normal;
    svg{width:30px;height:30px;}
  }

  .navItemCat{@include sec-default();margin:10px 0 0;font-weight:bold;text-decoration:underline;font-size:16px;text-transform:uppercase;color:$white;}
  .navItem,.dropLi{@include sec-default();line-height:1em;margin:0 0 25px;
    &.mob{display:block;}
    &.fw{display:none;}

    &.withDrop{margin:10px 0 0;
      & > a{text-decoration:underline;font-weight:900;text-transform:uppercase;font-size:15px;letter-spacing:2px;color:$logold;} //display:none;}
    }
    &.attn a{color:$logold;}

    a, button{font-weight:400;color:$white;text-transform:none;font-size:19px;line-height:1em;padding:0;
      &:hover{opacity:.7;color:$white;}
    }
    span.num, span.parentIcon{display:none;}
  }
  .custDrop{@include sec-default();margin-top:5px;background-color:transparent;padding:0;position:relative;box-shadow:none;}
}




  ////////////////////////////////////////////////////////
  // &.XhomeHeader{background-color:$logoBlue; // IF LOGO IS INCLUDED, ABSOLUTE + BEFORE WITH ^ HEIGHT
  //   &::before,.logoBlk,.navBarCustom{display: none;}
  //   .mobNav{display:block;position:absolute;margin-top:0;top:6px;}
  //   .mobileNavBtn{border:1px solid rgba($white,.5);}
  //   .topBar .socialLinks{text-align:right;margin-right:5%;}

  //   // .logoBlk{width:120px;}
  // }
  ////////////////////////////////////////////////////////
