@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;800;900&family=Birthstone+Bounce:wght@400;500&display=swap');
// family=Courgette&
@import './assets/global';
@import './assets/variables';
@import './assets/mixins';

*, *::before, *::after {box-sizing:border-box;}
body{margin:0;}
.ecfWrap{@include sec-default();position:relative;}
.MainContent{@include sec-default();
  @media (min-width:992px) {min-height:55vh;}
  @media (min-width:1024px) {min-height:65vh;}
}

.ToTopBtn{right:15px !important;
  svg{margin-bottom:4px;}
  // @include bp(tl) {bottom:50px !important;right:15px !important;}
  // @include bp(tb) {bottom:15px !important;}
  // @include bp(oc) {bottom:45px !important;}
}

li{list-style-type:none;}

form{
  // .formGroup{@include sec-default();margin-bottom:15px;}
  input:not(input:not([type="submit"])),textarea{@include sec-default();font-family:$body;font-size:15px;padding:2px 3px 3px 5px;border-color:rgba($black,.1);letter-spacing:.5px;text-transform:uppercase;margin-bottom:15px;}
  input{min-height:40px;}
  textarea{min-height:100px;padding-top:5px;}
  input[type="submit"]{border:none;font-family:$body;text-transform:uppercase;letter-spacing:2px;float:right;max-width:100px;
    &:hover{cursor:pointer;}
  }
}

@include bp(br){
.insideN.custIN{width:100%;}
.insideAlt.custIA{width:90%;}
}