// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.SponsorLoop{@include sec-default();text-align:center;padding:40px 0;
  // &.withBorder{border-bottom:1px solid rgba($black,.1);margin-bottom:20px;}
  &.default{background-color:$f7;}
  &.alt{background-color:rgba($lt-blue,.4);}

  .partner,img{border-radius:10px;}
  .partner{display:inline-block;background-color:$white;margin:10px .5%;}
  .partnerLink{@include sec-default();}
  img{border:1px solid rgba($gray,.2);box-shadow:1px 1px 1px rgba($gray,.4);}
  .partnerLink:hover{cursor:pointer;
    img{opacity:.8;border-color:rgba($blue,.8);}
  }

  &.tier1 .partner{max-width:400px;}
  &.tier2 .partner{width:30%;}
  &.tier3{padding-left:10px;padding-right:10px;
    .partner{width:24%;}
  }
  &.tier4{padding-bottom:60px;
    .partner{width:18%;}
  }

  @include bp(tb){padding:20px 0;
    &.tier1 .partner{max-width:350px;}
    &.tier4{padding-bottom:40px;}
  }

  @include bp(md){padding:20px 0;
    &.tier1 .partner{max-width:300px;}
    &.tier2 .partner{width:30%;}
    &.tier3{padding-left:0;padding-right:0;
      .partner{width:26%;}
    }
    &.tier4 .partner{width:20%;}
  }
  
  @include bp(og){
    ul{@include center-block();}
    &.tier1 .partner{max-width:270px;}
    &.tier2,&.tier3,&.tier4{.partner{width:100%;}}
    &.tier2 .partner{max-width:235px;}
    &.tier3 .partner{max-width:200px;}
    &.tier4 .partner{max-width:165px;}
  }
}