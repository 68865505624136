// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.modalDialog{width:99%;max-width:950px !important;padding:40px 30px;
  p.h3{font-size:22px;font-weight:900;margin-bottom:20px;text-align:center;
  }
  .tborder{@include sec-default();}
  @include bp(br){p.h3{font-size:19px;}}
  @include bp(og){padding:15px;}
}

.donateModal{
  p span{color:$ecBlue;}
  p b{color:$logoBlue;}
  input{width:170px;@include center-block();}
  a{color:$black;}
}

.boardModal{text-align:center;
  h4{font-size:1.15rem;line-height:1.2em;}
  h4 b{color:$ecBlue;font-size:1rem;}
}

.orgmodal{text-align:center;
  .modalDialog{width:90%;max-width:800px !important;}
  .inside{width:95%;}
  img{max-width:200px;}

  @include bp(br){
    .modalContent p{font-size:16px;}
    img{max-width:150px;}
  }
}

