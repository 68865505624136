// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Foundation{@include sec-default();
  p.creed{text-align:left;color:#119ac5;font-weight:bold;font-style:italic;margin-top:15px;}

  .flyerContent{@include sec-default();margin-top:30px;padding:30px 0 0;background-color:#f6f6f6;
    p{font-weight:300;}
  }
  .modalLinks{@include sec-default();margin:10px 0 40px;
    .ecLink2{margin:0 10px;}
  }
  .logoList{@include sec-default();padding:30px 0 10px;background-color:$white;
    p{font-size:22px;font-weight:bold;margin-bottom:30px;}
    .llItem{display:inline-block;max-width:200px;margin:0 5px;padding:0;}
    .llItem.tcn img{padding:0 5px;}
  }



  @media (min-width:768px){
    .fullRow .med3Def{max-width:800px;display:flex;align-items:center;justify-content:space-between;}
    .logoList .med2Def{display:flex;align-items:center;justify-content:center;}
  }

  @include bp(tw){.logoList .med2Def{width:100%;}}  
  @media (max-width:959px){p.creed{font-size:16px;}}

  @include bp(md){
    .fullRow{
      .hlf1{margin-left:-2%;}
      .hlf2{width:51%;margin-right:-2%;}
    }

    .llItem{width:200px;}
  }

  @include bp(og){
    .fullRow{
      .hlf1,.hlf2{@include sec-default();margin:0;}
      img{max-width:250px;}
    }
    p.creed{max-width:500px;text-align:center;margin:20px auto 0;}

    .flyerContent{margin-top:20px;padding-top:15px;}
    .pclass p{font-size:16px;}
    .modalLinks{margin:0 0 20px;
      .ecLink2{margin:10px;}
    }

    .logoList{padding:15px 0 0;
      .med2Def{width:90%;max-width:400px;}
      p{font-size:19px;margin-bottom:0;}
      .llItem{width:50%;max-width:100%;margin:10px 0;
        img{width:165px;@include center-block();}
      }
    }
  }

  @include bp(fi){
    .logoList .llItem{@include sec-default();margin:0 0;
      &.tcn img{padding:0;}
    }
  }
}
@media (min-width:1024px){
  .fullRow {
    .four.one{margin-left:-6%;}
    .four.two,.four.three{margin-left:-4%;}
  }
}