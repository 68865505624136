// background:linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.HomePage{@include sec-default();
  // UNUSED
  .CoundownBlend{@include sec-default();position:relative;z-index:0;overflow:hidden;background-color:$ddBg;
    @include sec-bgimage('./images/skyIP.jpg');background-position:top center;background-size:cover;
  
    .drawTb{max-width:540px;text-align:center;float:right;padding:225px 50px 220px;
      position:relative;z-index:0;&::before{@include before();background-color:rgba($ddBg,.5);filter: blur(10px);-webkit-filter:blur(10px);}
    }    
    h1, h2, h3, p, .csClock{@include tshadowl(rgba($black, .4));}
    h1,h2,h3,.csClock{color:$white;}
    h1{color:$orange;line-height:1em;font-size:105px;font-weight:800;font-family:$emph;letter-spacing:5px;margin-bottom:5px;}//letter-spacing:3px;
    h2{font-weight:300;font-style:italic;margin-bottom:0;
      font-size:23px;line-height:1.5em;// font-size:35px;
      span{font-size:32px;font-family:$emph;font-weight:bold;text-transform:none;letter-spacing:3px;}
    }
    h3{font-weight:600;font-size:24px;margin-bottom:30px;}
    .ecLinkRed{padding:7px 25px 10px;font-size:15px;}
    
    p.prize{font-size:45px;font-family:$emph;letter-spacing:2px;font-weight:bold;color:$pink;
      position:absolute;@include rotate(-35deg);left:-10%;top:23%;
    }
    .csClock{@include sec-default();position:relative;font-weight:800;padding-bottom:10px;font-size:50px;
      p{display:inline-block;text-transform:uppercase;font-size:13px;padding:0 20px;font-weight:normal;}//padding:0 5px;
    }
        
    @media (min-width:1600px){.drawTb{padding:255px 50px 260px;}}
    @media (min-width:1500px){background-position:top right;p.prize{left:-20%;top:26%;}}
  
    @include bp(tl){
      .drawTb{padding:205px 50px 180px;}
      h1{font-size:80px;}
      h2{font-size:21px;margin-bottom:5px;
        span{font-size:30px;}
      }
      h3{font-size:22px;}
      p.prize{font-size:37px;}
      .csClock{font-size:40px;
        p{padding:0 12px;}
      }
    }
  
    @include bp(tb){background-position:top right;
      .drawTb{padding:110px 60px;}
      h1{font-size:70px;letter-spacing:3px;}
      h2{font-size:20px;
        span{font-size:26px;letter-spacing:1.5px;}
      }
      h3{font-size:20px;}
      p.prize{font-size:29px;top:17%;left:-4%;@include rotate(-30deg);}
      .csClock{font-size:33px;
        p{padding:0 6px;}
      }
      .ecLinkRed{font-size:14px;}
    }
  
    @include bp(md){
      .drawTb{padding:110px 40px 90px;}
      h1{font-size:55px;}
      h2{font-size:18px;
        span{font-size:23px;}
      }
      h3{font-size:17px;}
      p.prize{font-size:26px;left:-10%;}
      .csClock{font-size:30px;
        p{font-size:12px;}
      }
      .ecLinkRed{padding:5px 20px 7px;font-size:13px;}
    }
  
    @include bp(oc){//background-position:center center;background-size:100%;
      background-image:url('../../assets/images/drawdownIP.jpg');background-size:cover;
      .drawTb{padding:20px 15px 30px;max-width:100%;width:100%;
        &::before{background-color:rgba($ddBg,.8);filter: blur(0px);-webkit-filter:blur(0px);
          background:linear-gradient(90deg, rgba($logoBlue, 0.7), rgba($ddBg, 0.8), rgba($logoBlue, 0.7));
        }
      }
      h1{font-size:35px;letter-spacing:1.5px;}//font-family:$body;}
      h2{font-size:15px;line-height:1.2em;
        span{font-size:inherit;font-family:$body;letter-spacing:0px;}
      }
      h3{margin-bottom:2px;}
      p.prize{font-size:18px;position:relative;left:auto;top:auto;@include rotate(0deg);font-family:$body;letter-spacing:0;margin:0 0 10px;}
      .csClock{font-size:20px;line-height:1em;
        p{padding:0 9px;margin-bottom:0;line-height:1em;}
        p span{display:none;}
      }
      .ecLinkRed{font-size:12px;}
    }
  }

  // RUN, not plunge
  .simpleHero{@include sec-default();padding:40px;background:rgba($teal,.5);position:relative;z-index:0;text-align:center;
    .imageWrap{width:100%;max-width:1550px;@include center-block();position:relative;z-index:0;box-shadow:0px 10px 20px rgba($black, .4);}// @include sec-default();
    .content{position:absolute;width:100%;bottom:0;left:0;z-index:2;background-color:$blue24;padding:25px 0 20px;}
    .med3Def{max-width:700px;}
    .med3Def.with3{max-width:900px;width:95%;}

    img{position:relative;z-index:0;}
    .heroImage.vidStyle{display:none;}

    .text,.csClock,.regSide{display:inline-block;}
    .text{float:left;}
    .csClock{font-size:50px;font-weight:800; color:$white;
      &:not(.with3){float:right;}
    }
    .regSide{float:right; margin-top:-8px;}//margin-top:10px;}
    .regSide .clearfix{margin-top:15px;}

    &,h1,h2,p{color:$white;text-shadow:0px 1px 1px rgba($black,.3);}
    h1{font-size:18px;letter-spacing:1px;line-height:1em;margin-bottom:5px;text-transform:uppercase;}
    h2{margin-bottom:0;
      span{font-size:33px;font-weight:bold;}
    }
    p{display:inline-block;text-transform:uppercase;font-size:13px;padding:0 20px;font-weight:normal;margin-bottom:0;}


    @media (max-width:1700px){.content{padding-bottom:17px;}}
    @include bp(tx){padding:40px;
      .content{padding-bottom:12px;}
    }
    @include bp(tw){padding:0px;
      .imageWrap{box-shadow:none;}
    }
    @include bp(nn){
      .med3Def{max-width:600px;}
      h1{font-size:16px;}
      h2{font-size:20px;
        span{font-size:28px;}
      }
      .csClock{font-size:40px;}
      p{font-size:12px;padding:0 15px;}
      .ecLinkRed{padding-left:20px;padding-right:20px;}
    }

    // @include bp(mb){ // KPKP WHEN ONLY 2
    @include bp(og){
      .content{padding:12px 0 14px;background-color:rgba($blue24,.7);}
      .text,.csClock,.csClock.with3, .regSide{width:100%;display:block;float:left;}
      .csClock{font-size:25px;margin-top:10px;}
      h1{font-size:15px;letter-spacing:0;}
      h2{font-size:15px;line-height:1.5em;
        span{font-size:21px;}
      }
      p{display:none;}

      .regSide{margin:15px 0 5px;clear:both;}
      .regSide .clearfix{display:none;}
      .ecLinkRed{padding:7px 15px 8px; margin:0 7px;}
    }
    @include bp(xx){
      .regSide .link2{display:none;}
    }
  }

  // PLUNGE, not run
  .plungeHero{position:relative;z-index:0;text-align:center;background:$bgBlue;padding:60px;
    a{@include center-block();}
    a:hover{opacity:.9;
      h1{opacity:.9;}
    }
    img{box-shadow:0px 10px 30px rgba($black, .2);}
    h1{color:$white;text-shadow:0px 1px 1px rgba($black,.5);margin:20px 0 0;line-height:1em;}
    h1 span{text-transform:uppercase;letter-spacing:2px;font-size:16px;font-weight:bold;}

    @include bp(dt){padding:4%;}
    @include bp(nn){padding:40px;}
    @include bp(md){padding:25px;
      h1{font-size:24px;}
    }
    @include bp(oc){padding:20px 15px;
      h1{font-size:19px;line-height:1.2em;}
      h1 span{font-size:14px;}
    }

    @include bp(xs){
      h1{font-size:17.5px;}
      h1 span{font-size:13.5px;letter-spacing:0;font-weight:normal;}
    }
  }

  // WITH OVERLAY MESSAGE -- closed registration message, etc...
  .outerMsg{@include sec-default();
    .msgBox{@include sec-default();padding:20px 10px;text-align:center;background-color:$red;}
    h4,p{color:$white;}
    h4{margin:0;text-transform:uppercase;letter-spacing:2px;font-weight:bold;font-size:22px;}
    p{font-size:19px;line-height:1.6em;margin-bottom:0;width:90%;@include center-block();max-width:650px;}
    p b{text-decoration:underline;}
    p br{display:none;}
    
    @media (min-width:1024px){display:none;}
    @include bp(og){
      .msgBox{padding:12px 10px 15px;}
      h4{font-size:19px;}
      p{font-size:17px;max-width:500px;}
      p br{display:block;}
    }
    @media (max-width:525px){
      .msgBox{max-width:100%;}
      h4{font-size:18px;}
      p br{display:none;}
    }
  }

  .welcomeBox{@include sec-default();padding:70px 0;position: relative;z-index:0;
    .top{@include sec-default();text-align:center;margin-bottom:20px;}
    h4{font-size:1rem;margin-bottom:2px;}
    h3{font-size:30px;margin-bottom:20px;line-height:1.2em;font-weight:bold;}
    .ecLinkRed{margin:0 3px;}

    .rtext{float:right;width:75%;}
    .rls{width:20%;float:left;text-align:center;}
    .runLogo{display:block;margin-bottom:10px;height:100px;width:auto;float:right;
      &:first-child{height:60px;margin-bottom:15px;}
      &:last-child{height:105px;}
    }

    @include bp(tw){padding:40px 0 60px;}
    @include bp(tw){padding-bottom:40px;}

    @include bp(br){padding:30px 0;
      .med2Def{max-width:740px;}
      .top{margin-bottom:10px;}
      h3{font-size:26px;}
      .rtext{width:80%;}
      .rls{width:15%;}
    }

    @include bp(og){
      h3{font-size:24px;}
      h4{font-size:16px;}
      .top{margin-bottom:0px;}
      .rtext{width:100%;text-align:center;}
      .rls{@include sec-default();}
      .runLogo{display:inline-block;float:none;margin:0 10px 0;
        &:first-child{height:65px;margin-bottom:0;}
      }
    }

    @include bp(oc){
      h3{font-size:22px;}
      h4{font-size:15px;}
      .ecLinkRed{font-size:14px;}

      .runLogo{margin:0 10px 0;
        &:first-child{height:55px;}
        &:nth-child(2){height:80px;}
        &:last-child{height:85px;}
      }
    }

    @include bp(fi){
      h4{max-width:250px;@include center-block();}
      h3{font-size:19px;margin:5px 0 10px;}
      .ecLinkRed{font-size:13px;}
    }
    @include bp(sm){
      .ecLinkRed{font-size:13px;max-width:150px;@include center-block();}
      .midLink{margin:10px auto;}
    } 
  }

  .runVideo{padding:60px 0 70px;
    .medDef{max-width:1200px;display:flex;align-items:center;justify-content:space-between;grid-column-gap:30px;position:relative;z-index:0;}
    .medDef::before{@include before();left:-5%;width:105%;height:60%;top:20%;background-color:$logold;}
    .il1{@include sec-bgimage('./images/videoShot.png');}
    .il2{min-width:330px;}
    h3{font-size:30px;margin:0;line-height:1.5em;font-weight:600;color:$black;}

    @include bp(tw){
      .medDef::before{left:-3%;width:104%;}
    }
    @include bp(tb){
      .medDef{grid-column-gap:20px;}
      .medDef::before{height:70%;top:15%;}
      .il2{min-width:230px;}
      h3{font-size:23px;}
    }
    @include bp(og){padding:40px 0 30px;text-align:center;
      .il1{background-image:url('../../assets/images/videoShotMob.png');}
      .medDef{width:94%;flex-direction:column;padding:0 5% 20px;grid-row-gap:15px;}
      .medDef::before{height:70%;top:30%;width:100%;left:0;}
      .il1, .il2{width:100%;}
      h3{font-size:19px;}
    }
    @include bp(sm){padding-bottom:20px;
      h3{font-size:18px;max-width:200px;@include center-block();}
    }
  }


  .HomeBody{@include sec-default();position: relative;z-index:0;}//background-image:url("../../assets/images/noisy.jpg");}
  .feedComboSpread{@include sec-default();text-align:center;position:relative;z-index:0;padding:100px 0;margin-top:30px;
    &::before{@include before();width:55%;background-color:$teal;}

    .lodge,.insta{display:inline-block;}
    .lodge{width:30%;float:left;margin-left:-10%;margin-top:20px;
      p{color:$white;font-size:25px;font-weight:bold;line-height:1.6em;
        text-transform:uppercase;letter-spacing:2px;@include tshadowl(rgba($black,.3));
        margin-bottom:5px;//margin-bottom:70px;
      }
      p span{font-family:$emph;letter-spacing:3px;font-size:37px;text-transform:none;}
    }
    .homeLodge{max-width:230px;@include center-block();box-shadow:0px 10px 20px rgba($black, .2); margin-top:20px;
      &.withp{
        p{background-color:$logoBlue;color:$white;padding:10px 15px;margin:15px 0 0;line-height:1.6em;font-weight:bold;@include tshadowl($black);}
        a{color:$white;}
        &:hover{opacity:1;}
        &:hover p{background-color:$teal;}
      }
    }

    .insta{width:80%;float:right;margin-right:-10%;position:relative;
      p.h3{font-size:31px;font-weight:900;font-family:$emph;letter-spacing:3px;
        color:darken($teal,30%);@include tshadowl(rgba($black,.3));
      }
    }
    .igPlug{@include sec-default();min-height:450px;}

    @media (min-width:1445px){
      &::before{width:50%;}
      .insta{margin-right:-15%;}
    }
    @media (min-width:1365px){.insta p.h3{position:absolute;@include rotate(-90deg);left:0;width:30px;margin:270px 0 0 -40px;}}

    @include bp(tl){
      .lodge {margin-top:40px;
        // p{margin-bottom:40px;}
      }
    }

    @media (max-width:1364px){
      &::before{width:52%;}
      .insta{margin-right:-4%;}
      .lodge{margin-left:-7%;
        p{font-size:21px;}
        p span{font-size:32px;}
      }
    }

    // width & height: calc(100vw/3 - 3*2);
    // .lightwidget__posts--grid .lightwidget__tile{webkit-flex-basis:33%;}

    @include bp(tw){padding:40px 0 35px;margin-top:0px;
      .medDef{max-width:1000px;}
      .igPlug{min-height:400px;}
      .insta{width:75%;margin-right:-2%;
        p.h3{font-size:27px;}
      }
      .lodge{width:25%;margin-left:-2%;margin-top:40px;
        p{font-size:20px;}
        p span{font-size:30px;letter-spacing:2px;}
      }
    }

    @include bp(tb){padding:40px 0 25px; .insta{margin-right:-3%;}}

    @include bp(br){padding:20px 0 35px;margin-top:0px;overflow: hidden;
      &::before{
        width:100%;transform:skewY(30deg);transform-origin:bottom left;}
        // width:100%;transform:skewX(55deg);transform-origin:bottom right;}
      .medDef{max-width:750px;}
      .insta,.lodge{@include sec-default();margin:0;}
      .insta p.h3{font-size:24px;}

      .lodge{@include sec-default();text-align:center;}
      // .lodge p, .homeLodge{display:inline-block;margin:0 20px;vertical-align:top;}
      .lodge p{margin-top:55px;}
      .homeLodge{max-width:200px;margin-top:30px;}
    }

    @include bp(og){
      .medDef{max-width:600px;}
      .insta p.h3{font-size:24px;}
      .lodge{margin-top:30px;}
      .homeLodge{max-width:180px;}
    }

    @include bp(oc){
      .igPlug{min-height:350px;}
      .insta p.h3{font-size:22px;}
      .lodge{margin-top:15px;
        p{@include sec-default();margin:0 0 30px;font-size:17px;}
        p span{font-size:27px;letter-spacing:2px;}
        .homeLodge{width:100%;clear:both;}
      }
    }

    @media (max-width:550px){.igPlug{min-height:300px;}}
    @media (max-width:500px){.igPlug{min-height:250px;}}
    @media (max-width:400px){.igPlug{min-height:220px;}}
  }
  .feedLodgeSolo{@include sec-default();background-color:$teal;text-align:center;position:relative;z-index:0;padding:30px 0px;margin-top:30px;
    .medDef{max-width:480px;}
    p{color:$white;font-size:25px;font-weight:bold;line-height:1.6em;margin-bottom:5px;
      text-transform:uppercase;letter-spacing:2px;@include tshadowl(rgba($black,.3));
    }
    p span{font-family:$emph;letter-spacing:3px;font-size:37px;text-transform:none;}
    .homeLodge{display:inline-block;width:48%;max-width:230px;margin:20px 0;box-shadow:0px 10px 20px rgba($black, .2);}
    .homeLodge.one{float:left;}
    .homeLodge.two{float:right;}
    
    @include bp(oc){padding:15px 0;
      p{font-size:21px;}
      p span{font-size:32px;}
    }
    @media (max-width:500px){padding-bottom:10px;
      p{@include sec-default();margin:0 0 30px;font-size:18px;}
      p span{font-size:25px;letter-spacing:2px;}
      .homeLodge{width:100%;max-width:200px;clear:both;}
      .homeLodge.one, .homeLodge.two{float:none;@include center-block();}
    }
  }

  .foundation{@include sec-default();padding:80px 0 120px;
    .motto{@include sec-default();text-align:center;
      p{font-style:italic;font-weight:bold;margin-top:10px;color:$ecBlue;width:50%;@include center-block();}
      img{width:95%;max-width:324px;}
    }

    .ecBody{@include sec-default();padding:50px 0;margin-top:20px;position:relative;z-index:0;
      &::before{@include after();background-color:$cream;width:70%;right:-7%;}
    }
    .tbHalf, .videoBox{display:inline-block;}
    .tbHalf{float:right;width:52%;margin-top:60px;
      p.h3{font-weight:800;font-size:19px;margin-bottom:0;}
      p{font-weight:300;}
    }
    .videoBox{float:left;width:53%;margin-left:-10%;}
    .ecLinkSm{padding-bottom:8px;}


    @media (max-width:1400px){overflow: hidden;
      &::before{right:-5%;}
      .videoBox{margin-left:-5%;}
      .tbHalf{margin-right:-3%;}
    }

    @include bp(tw){
      .motto p{width:55%;}
      .ecBody::before{right:-3%;}
      .videoBox{width:48%;margin-left:-3%;}
      .tbHalf{width:52%;margin-right:0;margin-top:20px;}
      .ecLinkSm{font-size:14px;}
    }

    @include bp(tb){padding:50px 0 60px;
      .tbHalf{padding-right:10px;}//margin-top:0;}
    }

    @media (max-width:959px){padding-bottom:20px;
      .medDef{max-width:600px;}
      .motto p{width:100%;max-width:500px;font-size:16px;}
      .ecBody{margin-top:10px;padding:20px 0;}
      .ecBody::before{width:120%;right:-10%;height:70%;bottom:auto;top:0;}
      .tbHalf,.videoBox{@include sec-default();padding:0;margin:0;}
      .tbHalf{margin-bottom:30px;
        p.h3{font-size:18px;}
      }
    }
    
    @include bp(og){padding-top:30px;
      .medDef{max-width:100%;}
      .ecBody{padding:20px;}
      .ecBody::before{width:105%;right:-2.5%;}
    }

    @include bp(fi){
      .ecBody{padding:15px 0;}
    }
  }
}