// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.EventSubnav{@include sec-default();text-align:center;
  // ul{margin:0;}
  li{display:inline-block;margin:0 3px 10px;
    &:first-child .snLink,&:nth-child(5) .snLink{background-color:$teal;}
    &:nth-child(2) .snLink,&:nth-child(6) .snLink{background-color:$logoBlue;}
    &:nth-child(3) .snLink,&:nth-child(7) .snLink{background-color:$blue;}
    &:nth-child(4) .snLink,&:nth-child(8) .snLink{background-color:$ecBlue;}
    &:hover{opacity:.8;@include ease(.3s);}
  }

  .snLink{@include sec-default();color:$white !important;padding:10px 25px 11px;text-shadow:0px 1px 2px rgba($black,.2);
    box-shadow:0 10px 25px rgba($black,.15);letter-spacing:.6px;font-size:15px !important;font-weight:bold;line-height:1em;
  }

  .altColor{margin-top:10px;
    li:nth-child(3) .snLink,li:nth-child(7) .snLink{background-color:$white; color:$blue !important;}
  }

  @include bp(tb){&.sl6 .esWrap{@include center-block();width:75%;}}
  @include bp(tn){&.sl6 .esWrap{width:85%;}}
  @include bp(md){
    li{margin-bottom:5px;}
    .snLink{font-size:14.5px !important;padding:8px 20px 8.5px;}
  }
  @include bp(og){&.sl6 .esWrap{width:95%;}}

  // @include bp(oc){
    // li{@include sec-default();}
    // .snLink{max-width:250px;@include center-block();padding:7px 20px 8px;}
  // }


}