@import './variables.scss';
@import './mixins.scss';

html {line-height:1.6em;font-size:17px;color:$black;font-family:$body;letter-spacing:.3px;}
h1, h2, h3, h4, h5, p{margin:0 0 15px;font-family:$body;
  // span{font-size:inherit;color:inherit;font-weight:inherit;font-family:inherit;}
  &.up, span.up{text-transform:uppercase;}
  &.cap, span.cap{text-transform:capitalize;}
  &.em, span.em{font-style:italic;}
  &.bold, span.bold{font-weight:bold;}
  &.red{color:$red;}
}
p{font-size:1rem;}
h1{font-size:1.9rem;line-height:1.3em;}//font-weight:800;}
h2{font-size:1.6rem;line-height:1.3em;}
h3{font-size:1.2rem;}
@include bp(tb) {
  h1{font-size:1.8rem;}
  h2{font-size:1.3rem;}
  h3{font-size:1.2rem;}
}
@include bp(br) {h1{font-size:1.65rem;}}
// @include bp(og) {p{font-size:17px;}}

a{text-decoration:none;
  &:hover{opacity:.8;text-decoration:none;@include ease(.3s);}
}
button{background:none;border:none;border-radius:0;color:inherit;font-weight:inherit;font-family:inherit;
  font-size:inherit;letter-spacing:inherit;
  &:hover{cursor:pointer;color:inherit !important;}
}
img{@include center-block();}
ul {margin:0;padding-left:0;}//list-style-type:none;
// li{list-style-type:none;}

.fullSec{@include sec-default();}
.inside, .insideAlt, .medDef, .med2Def, .med3Def, .med4Def, .insideXl, .insideN, .insideNrw{@include center-block();width:90%;max-width:1700px;}
.insideXl{width:95%;}
.inside{width:85%;}
.insideAlt{width:80%;}
.medDef{max-width:1080px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}

.fullRow{@include sec-default();
  .hlf1,.hlf2,.col{display:inline-block;vertical-align:top;}
  .hlf1,.col1{float:left;}
  .hlf2,.col3{float:right;}
  .equal{width:47%;}
  .small{width:32%;}
  .large{width:65%;}
  .three{width:30%;}
  .four{width:25%;}

  @include bp(br){&.mobile0 .four{width:40%;}}

  @include bp(og){
    &.mobile0,&.defaultOG{
      .hlf1,.hlf2,.col{@include sec-default();}
    }
  }
}
.centerBlock{@include center-block();}
.bmargin0{margin-bottom:0;}
.bmargin05{margin-bottom:5px;}
.bmargin1{margin-bottom:10px;}
.tmargin0{margin-top:0;}
.tmargin2{margin-top:20px;}
.textCenter{text-align:center;}
.textLeft{text-align:left;}
.textRight{text-align:right;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1,.spacer2,.spacer25,.spacer3,.spacer4{@include sec-default();}
.spacer1{height:10px;}
.spacer2{height:20px;}
.spacer25{height:25px;}
.spacer3{height:30px;}
.spacer4{height:40px;}

hr{border-color:rgba($black,.1);
	&.hlf,&.hlfC{width:50%;}
  &.hlfC, &.center{@include center-block();}
	&.less{width:25%;}
	&.l120{width:120px;}
}
.clearfix::after, .clearfixBtn::after,.clearfixBtn2::after {content:"";clear:both;display:table;}
.clearfix.btn, .clearfixBtn{@include sec-default(); margin-top:20px;}
// .clearfixBtn2{@include sec-default(); margin-top:10px;}
.clearBorder, .clearBorderWhite, .clearBorderLeft{@include sec-default(); height:50px; position:relative; z-index:0; margin:0px 0 20px;
  &::before{@include before(); width:1px; left:49.7%; background-color:rgba($black,.2);}
}
.clearBorderWhite::before{background-color:rgba($white, .3);}
@include bp(br){
  .clearfix.brSpace1{margin-bottom:10px;}
  .clearfix.brSpace2{margin-bottom:20px;}
}
.visuallyHidden {@include visuallyHidden();}
.imgResponsive {max-width:100%; height:auto; display:block;}
ul.disc li{list-style-type:disc;}

.ecLink,.ecLink2,.ecLink3,.ecLink4,.ecLinkRev,.ecLinkSm,.ecLinkRed,.ecLinkXl,.regBtn{@include link($logoBlue, $white); padding:10px 25px 13px;
  box-shadow:0 10px 25px rgba($black,.15);letter-spacing:.6px;
  &.inline{margin:3px;}
  &.xl{width:100%;@include sec-default();}
  &:hover{color:$white !important;}
  @include bp(tb) {font-size:15px;}
}
.ecLink2{background-color:$teal;}
.ecLink3{background-color:$blue;}
.ecLink4{background-color:$ecBlue;}
.ecLinkSm{padding:5px 20px 7px;font-size:15px;}
.ecLinkRev{background-color:$white;color:$logoBlue;
  &:hover{color:$logoBlue !important;}
}
.ecLinkRed{background-color:$red2;}
.regClear{height:15px;}
@media (max-width:767px){
	.regBtn{font-size:14px;}
	.regClear{height:10px;}
}
.signUp {width:180px;height:45px;padding-top:8px;text-align:center;z-index:100;font-size:17px;text-transform:uppercase;letter-spacing:1px;
  background-color:darken($red, 10%);color:$white;font-weight:bold;@include tshadowl(rgba($black,.7));border:1px solid rgba($black, .3);
  &.suMobile{display:none;}
  &:hover {opacity:.8;color:$white;}

  @media (min-width:1024px){@include rotate(270deg);position:fixed;top:30%;right:-68px;}
  @include bp(tw){height:40px;padding-top:6px;right:-70px;}
  @include bp(br){padding:7px 20px;font-size:16px;letter-spacing:2px;box-shadow:0px 5px 15px rgba($black,.3);}
  @include bp(og){font-size:14px;}

}

.textLink{font-weight:600; color:$logoBlue;
  &.und {text-decoration:underline;}
  &:hover{color:darken($blue,15%) !important; opacity:1;}
}
