// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Registration{@include sec-default();
  .vipNote{font-size:.85em;font-weight:normal;color:$red;}

  .note{@include sec-default();background-color:darken($cream, 5%);padding:10px 0;
    p{font-size:17px;margin:0;color:$red;letter-spacing:0px;font-weight:bold;text-transform:uppercase;}
    span{text-decoration:underline;}

    @include bp(og){
      p{width:95%;max-width:300px;@include center-block();letter-spacing:0;}
    }
  }
  // when there are 3 levels, and tix row is fw
  // .embass li:last-child{font-weight:inherit;color:inherit;padding-top:0px;border-top:none;text-transform:none;width:100%;}
  // tr.tix{border-top:15px solid $blue;padding-top:15px;
  //   td{@include sec-default();}
  // }
  .embass li:last-child{font-weight:inherit;padding-top:0px;border-top:none;text-transform:none;width:100%;}
  li.age, .embass li:last-child{color:$bgBlue;}
  li.age{font-style:italic;letter-spacing:0;}
}