@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Footer {@include sec-default();position:relative;z-index:0;background-color:$white;border-top:7px solid $black;text-align:center;padding:20px 0;
  .ftCol{display:inline-block;vertical-align:top; margin:0 20px;}//width:30%;}
  // .fc2{float:left;}
  // .fc3{float:right;}
  // .fc1{float:left;width:32%;margin-left:-2%;}
  // .fc3{float:right;width:32%;margin-right:-2%;}
  .logo1,.logo2{display:inline-block;}
  // .logo1{width:26%;}
  // .logo2{width:73%;}
  .logo1{height:89px;}
  .logo2{height:74px;}
  p.copy{font-size:13.6px;font-weight:300;margin:5px 0 0;line-height:1.5em;}

  @include bp(og){
    .ftCol{@include sec-default();margin:0;}
    .fc2{margin-bottom:20px;}
    .logo1{max-width:75px;height:auto;}
    .logo2{max-width:225px;height:auto;}
  }
  // @include bp(tb){
    // .medDef{width:97%;}
    // .fc1,.fc3{margin:0;}
    // .fc2{margin-left:3%;}
  // }

  // @include bp(br){//padding:5px 0;
    // .medDef{max-width:700px;}
    // .fc1,.fc2,.fc3{@include sec-default();margin:10px auto;}
    // .logo1,.logo2{width:auto;margin:0 5px;}
    // .logo1{max-height:90px;}
    // .logo2{max-height:80px;}
    // .fc1{max-height:80px;overflow:hidden;}
  // }
}