@import '../../assets/global.scss';
@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.Social{display:inline-block;
  li {display: inline-block; margin:0 5px;
    &:last-child{margin-right:0;}
  }
  a, span {color: $black;}
  svg {width:18px; height:18px;}

  &.topbar {
    li{margin:0 10px;}
    a, span {color: $white;}
    svg {width:17px; height:17px;}
  }

  &.footer span {color: $teal;}
  &.contact{margin-bottom:10px;}
  
  @include bp(tb) {
    svg {width:18px; height:18px;}
  }
}