// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.RegisterNow{@include sec-default();

  // iframe{height:600px;}
  iframe{box-shadow:0px 10px 20px rgba($black,.2);
    @media (min-width:900px){border-top:20px solid #f5f5f5;}
  }
  form{padding-top:10px !important;}


  .msgBox{@include sec-default();padding:20px 10px;text-align:center;background-color:$red;
    h4,p{color:$white;}
    h4{margin:0;text-transform:uppercase;letter-spacing:2px;font-weight:bold;font-size:22px;}
    p{font-size:19px;line-height:1.6em;margin-bottom:0;width:90%;@include center-block();max-width:650px;}
    p b{text-decoration:underline;}
    p br{display:none;}
    
    @include bp(og){
      .msgBox{padding:12px 10px 15px;}
      h4{font-size:19px;}
      p{font-size:17px;max-width:500px;}
      p br{display:block;}
    }
    @media (max-width:525px){
      .msgBox{max-width:100%;}
      h4{font-size:18px;}
      p br{display:none;}
    }
  }
}