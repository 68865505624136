// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.DrawDown{@include sec-default();}
$ddcolor:$teal;
.ddPlax{width:1080px;margin-left:-8.8%;padding:30px 30px 40px;margin-top:30px;position:relative;z-index:0;overflow:hidden;
  background-color:$ddcolor;
  &::after{@include after();width:40%;right:0;background-color:darken($ddcolor, 12%);}
  .divShape{position:absolute;top:0;right:0;height:100%;@include rotate(90deg);
    svg{position:relative;height:200px;}
    path{fill:darken($ddcolor, 12%);}
  }

  .il, ul{display:inline-block;}
  ul{width:50%;float:right;}
  .il{width:35%;float:left;text-align:left;margin:25px 0 0 5%;
    p{color:$white;}
    p.first{font-weight:bold;margin-bottom:8px;}
    p:not(.first){padding-left:15px;line-height:1.3em;
      span{color:inherit;font-style:normal;}
      &::before{@include before();width:5px;height:5px;top:50%;margin-top:-2.5px;background-color:$white;border-radius:50%;}
    }
  }

  @include bp(tw){width:117.7%;margin-left:-8.8%;}
  @include bp(tb){padding:30px 10px 40px 30px;
    ul{width:55%;}
    .il{width:38%;margin:25px 0 0 0;} 
  }

  @include bp(br){padding:20px 0px 20px 20px;
    &::after{display:none;}
    .divShape{@include rotate(0deg);}
    ul,.il{width:95%;@include center-block();}
    .il{margin:0 auto 30px;text-align:center;
      p:not(.first){margin-bottom:10px;
        &::before{left:auto;margin-left:-15px;}
      }
    }
    ul{max-width:670px;}
  }

  @include bp(md){width:117.8%;margin-left:-8.9%;}

  @include bp(og){width:111.2%;margin-left:-5.6%;margin-top:15px;padding:15px 0 30px;}

  @media (max-width:550px){
    .il {
      p{max-width:300px;@include center-block();}
      p:not(.first){padding-top:10px;}
      p:not(.first)::before{left:50%;margin-left:-2.5px;top:2px;}
    }
  }
}

img.ec{display:inline-block;width:105px;margin:10px 10px 0;}
img.ip{display:inline-block;width:90px;margin:0 10px;}
@include bp(fi){
  img.ec{width:85px;margin-top:0;}
  img.ip{width:65px;}
}
.formRow{@include sec-default();margin-top:30px;
  p{font-family:$emph;font-weight:500;font-size:40px !important;color:$logoBlue !important;margin-bottom:0 !important;}
  p.red{color:$red !important;}
  @include bp(br){margin-top:20px;
    p.formHd{font-size:30px !important;margin-bottom:10px !important;}
  }
  @include bp(fi){p.formHd{font-size:25px !important;}}
}